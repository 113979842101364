import {
  Container,
  Stack,
  Box,
  Heading,
  Text,
  Icon,
  IconProps,
} from "@chakra-ui/react";

export const Hero = ({ rightComponent }: any) => {
  return (
    <Container maxW={"7xl"}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 6, md: 14 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "3xl", lg: "5xl" }}
          >
            <Text as={"span"} position={"relative"}>
              Get your Crypto Punk
            </Text>
            <br />
            <Text as={"span"} color={"red.400"}>
              and use everywhere!
            </Text>
          </Heading>
          <Box color={"gray.800"} fontSize="md">
            <Box mb={2}>
              Inspired by many other projects about NFT and crypto punks I just
              created this thing and shared to the world. There are only 99
              available.
            </Box>

            <Box mb={2}>
              You can get your own crypto punk: Connect your wallet like
              Metamask, switch to Rinkeby network and click on "Mint your punk"
              button.
            </Box>

            <Box>
              The best of all -{" "}
              <Text as="span" fontWeight="bold">
                it's 100% free
              </Text>
              .
            </Box>
          </Box>
        </Stack>
        {/* <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          {rightComponent}
        </Flex>
        */}
      </Stack>
    </Container>
  );
};

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
